import { Controller } from '@hotwired/stimulus';
import { put } from '@rails/request.js';

// Connects to data-controller="default-map-update"
export default class extends Controller {
  async updateDefaultMap() {
    await put(this.element.dataset.updatePath, {
      body: {
        default_map_id: this.element.value
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream'
    });
  }
}
